import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "es6-shim";
import { runWithAdal } from "react-adal";
import { msalInstance } from "./service/msalConfig";

import { initMsal } from "./authProvider";
const DO_NOT_LOGIN = false;

// runWithAdal(
//   authContext,
//   () => {
//     import("./indexApp");
//   },
//   DO_NOT_LOGIN
// );


initMsal(msalInstance);



