
import { loginRequest } from "./service/msalConfig";
import { EventType } from "@azure/msal-browser";

export async function initMsal(msalInstance){
     function login(){
      try{
        msalInstance.loginRedirect(loginRequest);
      }
         catch(error)
         {
          console.log(error);
         }
    }

    function getAccount() {
      const currentAccounts = msalInstance.getAllAccounts();
      var signedAccount;         
      if (!currentAccounts || currentAccounts.length < 1) {
          return null;
      } else if (currentAccounts.length > 1) {
        loginRequest.prompt = 'select_account';
        return msalInstance.loginRedirect(loginRequest);
      } else if (currentAccounts.length === 1) {
          signedAccount = currentAccounts[0];
          msalInstance.setActiveAccount(signedAccount);
      }          
      return signedAccount;
    }

    function checkLogin(){
        var signedInAccount = getAccount();
        if(signedInAccount)
          return import("./indexApp");
        else 
          login();
     }

     msalInstance.addEventCallback((event) => {
        // set active account after redirect
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
          const account = event.payload.account;
          msalInstance.setActiveAccount(account);
        }
      }, error=>{
        console.log('error', error);
      });

     await msalInstance.handleRedirectPromise();

    checkLogin();
}
