import { LogLevel, PublicClientApplication } from "@azure/msal-browser";
import { AuthenticationContext, adalFetch, withAdalLogin, adalGetToken } from 'react-adal';
import { ApiList } from "./urlConstants";

var getRedirectUri = ApiList.getRedirectUri;
var apiUri = ApiList.basePath;

var azureClientId = ApiList.azureClientId;
var azureTenantId = ApiList.azureTenantId;
var azureNDACAPIClientId = ApiList.azureNDACAPIClientId;
var getSignOutURL = ApiList.getSignOutURL;
var scope = ApiList.scope;

/**
 * Configuration object to be passed to MSAL instance on creation. 
 */
 export const msalConfig = {
    auth: {
        clientId: azureClientId, // This is the ONLY mandatory field that you need to supply.
        authority: `https://login.microsoftonline.com/${azureTenantId}` , // Defaults to "https://login.microsoftonline.com/common"
        redirectUri: getRedirectUri, // Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
        postLogoutRedirectUri: getSignOutURL, // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            }
        }
    }
};
export const msalInstance = new PublicClientApplication(msalConfig);

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit: 
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
    scopes: ["openid", "profile", "User.Read"]
};

export const logoutRequest = {
    account: msalInstance.getActiveAccount()
  };

export const tokenRequest = {
    scopes: ["User.Read"],
    forceRefresh: false // set this to "true" if you would like to skip a cached token and go to the server
  };

export const tokenRequestAPI = {
    scopes: [scope],
    forceRefresh: false // set this to "true" if you would like to skip a cached token and go to the server
};

/**
 * An optional silentRequest object can be used to achieve silent SSO
 * between applications by providing a "login_hint" property.
 */
export const silentRequest = {
    scopes: ["openid", "profile"],
    loginHint: "example@domain.net"
};

export const getToken = async () => {
     try{
        const account = msalInstance.getActiveAccount()
        var silentResult =  await msalInstance.acquireTokenSilent({
            account: account,
            scopes: tokenRequestAPI.scopes
          });
          return silentResult.accessToken;
     }
    catch(err){
        // If a silent request fails, it may be because the user needs
        // to login or grant consent to one or more of the requested scopes
        console.log("error in" + err);
        if (isInteractionRequired(err)) {
            var interactiveResult_1 = await msalInstance.acquireTokenPopup(Request);
            console.log('Acquired access token isInteractionRequired popup: ' + interactiveResult_1);
            return interactiveResult_1;
          } else {
     
                  // could also check if err instance of InteractionRequiredAuthError if you can import the class.
                  if (err.name === "InteractionRequiredAuthError") {
                    var interactiveResult_2 = await msalInstance.acquireTokenRedirect(Request);
                    console.log('Acquired access token at InteractionRequiredAuthError: ' + interactiveResult_2);
                    return interactiveResult_2;
                  }
                  else if (err.name === "ClientAuthError") {
                    var interactiveResult_3 = await msalInstance.acquireTokenRedirect(Request);
                    console.log('Acquired access token at ClientAuthError: ' + interactiveResult_3);
                    return interactiveResult_3;
                  }
                  console.log(err);     
          }
    }
}

function isInteractionRequired(error) {
    if (!error.message || error.message.length <= 0) {
      return false;
    }

    return (
      error.message.indexOf('consent_required') > -1 ||
      error.message.indexOf('interaction_required') > -1 ||
      error.message.indexOf('login_required') > -1
    );
  }
