export const NOTIFICATION_ROLES = [
    { label: "Primary IO-Portfolio", value: "Y" }, // "Y" is placeholder
    { label: "Equity Head", value: "IDMSEH" },
    { label: "ND Center", value: "ND_Center_-_Directorship_Systems_Access@ifc.org" },
    { label: "ND Compliance", value: "ND_Compliance_Counsel@ifc.org" },
    { label: "Corporate Portfolio", value: "CPM_iDMS@ifc.org" },
    { label: "Portfolio Manager", value: "P" },
    { label: "Budget Officer", value: "BR" }, // "BR" is placeholder
    { label: "NDAC-Platform", value: "ndac@ifc.org" }
];

export const ApiList = {
    
    IDMsBaseUrl: "https://idms.ifc.org/",
    NDDBaseUrl: "https://ndac.ifc.org/",
    IdmsToNddGetUrl: "https://ndac.ifc.org/api/getIDMSData",

    NdacFromEmail: "ndac@ifc.org",
    NdacHelpSupportNumber: "+1-202-473-2121",
    ITSupportEmail: "ifcappssupport@ifc.org",
    //-------------------- React Adal Configs for Authentication -------------------
    getRedirectUri: "https://ndac.ifc.org/",
    basePath: "https://ndac.ifc.org/api/",
    azureClientId: "2cb75d11-132a-40e8-a12c-7b7b041246ea",
    azureNDACAPIClientId: "cb6ebfa2-011f-46e8-bbab-2ddea978b5b6",
    azureTenantId: "31a2fec0-266b-4c67-b56e-2796d8f59c36",
    scope:"https://worldbankgroup.onmicrosoft.com/ifc-ndacdatabase_WebAPI_WebAPI/cb6ebfa2-011f-46e8-bbab-2ddea978b5b6/user_impersonation",

    //-------------------- Enterprise services ---------------------------------
    EnterpriseService: "https://apigwext.worldbank.org/iportal/v2.0/masterservicescore", //"https://api2oauth.ifc.org/MasterServicesCore",
    getServiceResponseData: "api/serviceResponseData",
    getPartnerName: "https://apigwext.worldbank.org/idms/partnerdata/v1.0/partner/getById/",//"https://api1.ifc.org/PartnerDataServices/partner/getById/",
    //-------------------- Enterprise services ---------------------------------
    //sectors: "api/Sectors",
    sectors: "api/ProjectSectors?$orderby=SectorCategoryName&$filter=SectorCategoryName ne ' '",
    countries: "api/Countries?$filter=IsSubRegion ne 'Y'",
    regions: "api/Regions?$filter=Code ne '0' and Code ne '8'&$orderby=Name",
    departments: "api/Departments",
    countries_exp: "api/Countries",
    //-------------------- Create Candidate
    getMasterData: "api/getMasterData",
    getMasterDataiDMS: "getMasterData",
    getCandidateDetails: "api/getCandidate",
    getCandidateHome: "api/CandidateHome",
    postCandidateDetails: "api/saveCandidate",
    checkCandidateStatus: "api/checkCandidate",
    sendRegistrationLink: "api/SendEmailNotificationCandidateProfile",

    //-------------------- Board Reports
    postBoardDetails: "saveBoardMeetingReport",
    getBoardMeetingDetails: "getBoardMeetingReport",
    updateBoardMeetingsDetails: "saveboardmeetingreport",
    getAllBoardReports: "getAllBoardMeetingReports",
    postBoardReportSchedule: "api/saveBoardMeetingReport",
    deleteBoardReportDetails: "deleteBoardMeetingReport",
    saveBoardCommitteeRoles: "saveBoardCommitteeRoles",
    getBoardCommitteeRoles: "getBoardCommitteeRoles",
    saveBoardMeetingMultiple: "saveBoardMeetingReports",

    //------------------- Committee Reports
    postCommitteeDetails: "saveCommiteeMeetingReport",
    getCommitteeMeetingDetails: "getCommiteeMeetingReport",
    updateCommitteeMeetingsDetails: "saveCommiteeMeetingReport",
    getAllCommitteeReports: "getAllCommitteeMeetingReports",
    postCommitteeReportSchedule: "saveCommiteeMeetingReport",
    deleteCommiteeMeetingReport: "deleteCommiteeMeetingReport",

    //---------------------- ND Center Home
    //getCandidates: "api/getCandidates",
    getCandidates: "api/getCandidatesNDAC",
    //---------------------- Candidate Profile
    //getCandidateAllDetails: "api/getCandidateProfile",
    getCandidateAllDetails: "api/getCandidateProfileNDAC",
    postApprovedRejectStatus: "api/saveCandidateStatus",
    updateCandidateDetails: "api/updateCandidateDetails",

    //-------------------- Create Candidate
    registerCandidate: "api/registerCandidate",
    getCandidateProfile: "api/getCandidateProfile",

    //-------------------- Login
    getAuthentication: "api/validateCandidateProfile",

    //-------------------- Partner Details Page
    getPCOI: "api/getPCOI",
    savePCOIData: "api/savePCOI",
    getAnnualSelfAssessmentDetails: "api/getAnualSelfAssessmentSummary",
    saveAnnualSelfAssessmentDetails: "saveNDSelfAssessment",
    getAnnualSelfAssessmentDetailsSingle: "getNDSelfAssessment",
    getDirectorSnapshotDetails: "api/getPartnerNDSeat",
    getBoardReportsSummary: "api/getBoardReportsSummary",
    getNDSeatHomeData: "getNDSeatHomeDataNDD",
    getFundSeatHomeData: "getFundSeatHomeData",
    getCommitteeReportSummary: "api/getCommiteeReportsSummary",
    getAnnualAssessmentDataForSubmitDocument: "getAnnualSelfAssessmentNDD",
    //--------------------- Home Page APIs
    getCandidateProfileSnapshot: "api/getCandidateProfileSnapshot",
    getSeatDetails: "getPartnerSeatSummary",
    getIFCSeats: "getIFCSeatCount",
    getMyPreselections: "api/getCandidatePreselections",

    //----------------------Search Candidate
    //searchCandidate: "api/searchCandidates",
    searchCandidate: "api/searchCandidatesNDAC",
    //--------------------- PO sumit
    saveCandidatePreselection: "api/SaveCandidatePreselection",

    //--------------------- ND Ending Memorendum
    saveNDEndingMemorandum: "saveNDEndingMemorandum",
    getNDEndingMemorandum: "getNDEndingMemorandum",

    //---------------------- Pending Tasks
    getPendingTaskfromNDD: "api/pendingTasksNDD",
    getPendingTaskfromIDMS: "pendingTasksiDMS",

    //---------------------- Configuration 
    getConfigurationTypes: "api/getConfigurationTypes",
    saveUpdateDeleteConfiguration: "api/saveConfiguration",

    //---------------------- Change Status
    updateCandidateStatus: "api/updateCandidateStatus",

    //---------------------- Landing Page
    reactivationRequest: "api/UpCandidtReact",

    //---------------------- Email templates
    getEmailTemplates: "api/GetEmailTemplates",
    saveEmailTemplate: "api/SaveEmailTemplate",
    sendEmailNotification: "api/SendEmail",
    uploadDocument: "api/upload",
    getDocID: "api/SaveDocumentsData",
    getPresignedUrl: "api/getPreSignUrl",

    //---------------------- IDD
    saveIDDFlag: "api/saveIDDAnalysis",
    getIDDFlag: "api/getIDDAnalysis",

    //---------------------- Resignation Letter
    updateResignationLettter: "updateResgnDtForNDDResgnLetterUpload",
    getUploadedFile: "api/getUploadedFileStrm",
    getCandidateChangeHistory: "api/GetAuditDetails",
    getS3RelativeURLByIdmsEntityId: "api/GetS3RelativeURLsByIdmsEntityId",
    getCompositeServiceData: "getCompositeServiceData",
    odsEndpoint: "ODS/ODS/iDMS_Assess_Budget_V",

    //---------------------- Get Role Nav Data
    getRoleNavData: "api/getRoleNavData",
    getTemplateFile: "api/getNdacTemplateFromS3",

    //--------------------- Registration Link
    externalCandidateRegistrationLink: "http://accessifcsecure.ifc.org/",

    // Plain ID
    getAccess: "api/getAccess",

    // Sign out URL
    getSignOutURL: "https://ifcfedservices.ifc.org/common/ndac_logout.jsp",

    logClientSideError: "api/log",

    // ND Support Center Link
    ndSupportCenterLink: "https://worldbankgroup.sharepoint.com/sites/ext/ifc/ifcnd/SitePages/Home.aspx",
    // LPAC Support Center Link
    lpacSupportCenterLink: "https://newifcintranet.ifc.org/department/private_equity_funds_dept",

    ibrdLink: "https://www.worldbank.org/en/who-we-are/ibrd",
    idaLink: "https://ida.worldbank.org/",
    ifcLink: "https://www.ifc.org/",
    migaLink: "https://miga.org/",
    icsidLink: "https://icsid.worldbank.org/en/",

    candidateProfilePhotoZoomUrl: "https://zoom.worldbank.org/photo/people",
    checkFormalWbgSatus: "api/CheckFormerWBG",
    privacyPolicyLink: "https://www.ifc.org/wps/wcm/connect/corp_ext_content/ifc_external_corporate_site/home/personal+data+privacy+policy",
    //----------- Sectors
    sectorData : "api/Sectors",
    saveExternalODSUPI : "api/saveExternalODSUPI",
    getHrData : "api/getHRData",
    getPartnerRoleEmails: "api/PartnerRoleEmails",
    pbiNDACWorkspaceURL: "https://app.powerbi.com/groups/63ea2527-22c6-4f6d-8881-45fa7884e7f5/list?experience=power-bi"
};
